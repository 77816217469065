.p-home {

}





/**
 * Logged in user section.
 */
.p-home-user {

	// component
	.c-user-box {
		width: 250px;

		margin: 0 15px 0 auto;
	}
}





/**
 * Team overview panel.
 */
.p-home-overview {

	&__last-visit {
		position: absolute;
		top: 5px;
		right: 20px;

		font-size: 0.65rem;
		text-shadow: 1px 1px 2px #000;
		text-align: right;
		text-transform: uppercase;
		line-height: 1.1;


		span {
			display: block;

			color: #d4ba97;
		}

		b {
			border-bottom: 1px dashed #b99a70;

			color: #b99a70;
		}
	}


	&__time {
		position: absolute;
		right: 10px;
		bottom: 20px;

		width: 200px;
		height: 60px;

		text-shadow: 1px 1px 2px #000;


		b {
			position: absolute;
			top: 0;
			right: 60px;

			font-size: 0.65rem;
			font-weight: normal;
			text-transform: uppercase;

			color: #d4ba97;
		}

		span {
			position: absolute;
			top: 0;
			right: 60px;

			font-size: 3rem;

			color: #D4BA97;
		}

		i {
			position: absolute;
			top: -7px;
			right: 10px;

			font-size: 40px;

			color: #d4ba97;
		}
	}
}


/**
 * User energy in overview panel.
 */
.p-home-energy {
	position: absolute;
	left: 5px;
	bottom: 0;


	&__row {
		display: flex;
		align-items: center;

		margin-bottom: 5px;
	}

	&__type {
		flex: 1 0 auto;

		min-width: 80px;
		margin: 0 5px 0 30px;

		font-size: 0.65rem;
		text-transform: uppercase;
		text-shadow: 1px 1px 1px #000;


		b {
			font-size: 1rem;
		}

		i {
			font-size: 20px;
		}
	}

	&__countdown {
		flex: 1 0 50px;

		position: relative;
		top: 3px;

		width: 50px;
		margin: 0 5px;

		font-size: 1rem;
		text-align: right;
		text-shadow: 1px 1px 1px #000;

		color: #DCC5A5;
	}

	&__next-text {
		position: absolute;
		top: -10px;
		right: 3px;

		width: 175px;

		font-size: 0.65rem;
		text-align: right;
		text-transform: uppercase;
		text-shadow: 1px 1px 2px #000;

		white-space: nowrap;

		color: #d4ba97;
	}

	&__bonus {
		position: absolute;
		bottom: 85px;
		right: 0;


		// component
		.c-notification {

		}
	}


	// component
	.c-bar {

	}
}


/**
 * Gained since last visist section.
 */
.p-home-gained {
	position: absolute;
	top: 40px;
	right: 18px;

	display: flex;
	justify-content: flex-end;


	&__cell {
		flex: 1 0 auto;

		display: flex;
		flex-flow: column nowrap;

		min-width: 60px;

		text-align: center;
		text-shadow: 1px 1px 1px #000;

		color: #b79c78;


		&.-category-gold {
			color: #c1a02d;
		}
	}

	&__icon {
		height: 35px;

		font-size: 26px;


		// component
		.c-rank {
			display: inline-flex;


			&:before {
				font-size: 26px;

				color: #b79c78;
			}
		}
	}

	&__title {
		font-size: 0.65rem;
		text-transform: uppercase;
	}

	&__nr {
		font-size: 1rem;
		font-weight: bold;


		b {
			padding-right: 7px;
		}
	}
}






/**
 * Play button section.
 */
.p-home-play {
	$c: &;

	position: relative;

	height: 460px;

	background-color: #020202;


	// layout
	.content__bg {
		&:before {
			top: 0;
			left: 0;
			right: 0;

			height: 50px;

			background: linear-gradient(#271d10 40%, rgba(0,0,0,0));
		}
	}


	&__button {
		position: absolute;
		top: 79px;
		left: 295px;

		width: 194px;
		height: 178px;

		filter: contrast(85%);
		animation: indexPlayButton 3s ease infinite;

		cursor: pointer;


		&:hover {
			color: #fff;

			#{$c}__img {
				filter: contrast(140%);
			}
		}

		&.-c-clicked {
			filter: brightness(1);
			animation: indexPlayButtonClicked 1s ease;
		}
	}

	&__img {
		width: 194px;
		height: 178px;

		background: 0 0 no-repeat;

		transition: filter 250ms linear;
	}

	&__text {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;


		h1,
		h2 {
			position: absolute;
			right: 0;
			left: 0;

			margin: 0 auto;
			padding: 0;

			text-align: center;
		}

		h1 {
			top: 45px;

			font-size: 3rem;
			letter-spacing: -4px;
			text-shadow: 1px 1px 2px #fff,
						0 0 5px #fff;

			color: #253A2A;
		}

		h2 {
			top: 100px;

			font-size: 0.7rem;
			font-weight: 700;
			letter-spacing: 2px;
			text-shadow: 1px 1px 1px #fff,
						0 0 2px #fff;

			color: #1A2B1E;
		}
	}



	@include for-phone-landscape-up {
		&__button {
			left: 295px;
		}
	}
}





/**
 * About text section.
 */
.p-home-about {
	position: relative;
	min-height: 100px;

	margin-top: -50px;
	margin-bottom: 10px;

	background: linear-gradient(#020202, #291D12);


	// layout
	.content__padding {
		padding: 0 30px;
	}

	// component
	.c-page-desc {
		margin-top: -35px;
	}
}





/**
 * Recent LW section.
 */
.p-home-items {
	padding: 8px 5px;


	&__inner {
		display: flex;
		flex-flow: row wrap;
	}

	&__item {
		display: flex;
		flex-flow: column nowrap;

		margin: 0 10px 8px 0;
	}

	&__team {
		align-self: flex-start;

		max-width: 110px;
		margin-left: 2px;
		padding: 3px 12px 3px 10px;

		border-radius: 0 0 4px 4px;

		font-size: 0.65rem;
		white-space: nowrap;
		text-overflow: ellipsis;

		color: #D4B794;
		background-color: rgba(#000, 0.4);

		cursor: pointer;
		overflow: hidden;
	}

	&__more {
		margin: 8px 0 5px 25px;


		&.c-more-button {

		}
	}


	// component
	.c-item {
		width: auto;
		min-width: 110px;

		cursor: pointer;
	}
}





/**
 * New teams section.
 */
.p-home-teams {
	display: flex;
	flex-flow: row wrap;


	// component
	.c-user-box {
		width: 200px;

		margin: 0 10px 8px 0;
	}
}





/**
 * Highlighted section
 */
.p-home-highlighted {
	display: flex;


	// page
	.p-home-replay {

	}

	// page
	.p-home-ninja {
		flex: 0 0 260px;

		width: 260px;
		margin-left: 50px;
	}
}


/**
 * Highlighted replay section.
 */
.p-home-replay {

	&__matchup {
		position: relative;

		height: 120px;
		margin: 20px 0;


		// component
		.c-user-box {
			position: absolute;

			width: 200px;


			&:nth-child(1) {
				top: 0;
				left: 10px;
			}

			&:nth-child(2) {
				top: 50px;
				left: 130px;
			}
		}
	}

	&__vs {
		position: absolute;
		top: 30px;
		left: 85px;

		font-size: 3rem;
		font-weight: 700;

		color: #F19835;

		z-index: 2;
	}

	&__watch {
		&.c-button {
			position: absolute;
			top: 8px;
			left: 220px;


			z-index: 3;
		}
	}

}


/**
 * Highlighted ninja section.
 */
.p-home-ninja {

	// module
	.m-card-container {
		margin-top: 10px;
	}

	// component
	.c-user-box {
		width: 186px;

		margin-top: 10px;
	}
}